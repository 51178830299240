.vinyl {
  position: absolute;
  height: 250px;
  width: 250px;
  left: 150px;
  z-index: 1;
  display: block;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  background-image: url(/public/assets/images/vinyl.png);
  background-size: contain;
}

.cover {
  position: absolute;
  height: 250px;
  width: 250px;
  left: 0px;
  z-index: 33;
}

.wallybackground {
  padding-top: 55px;
  background-image: url("https://bibotarifa-chillout.web.app/assets/backWally.png");
  background-repeat: repeat;
  /* background-color: #f9ad84;  */
}
section {
  min-height: 100vh;
  width: 100%;
}
.cover1 {
  background-image: url(/public/assets/images/cover1.jpg);
  background-size: contain;
}

.cover2 {
  background-image: url(/public/assets/images/cover2.jpg);
  background-size: contain;
}

.cover3 {
  background-image: url(/public/assets/images/cover3.jpg);
  background-size: contain;
}

.cover4 {
  background-image: url(/public/assets/images/cover4.jpg);
  background-size: contain;
}

.album {
  position: relative;
  height: 250px;
  width: 400px;
  float: left;
  /* margin-right: 200px; */
  /* margin-top: 50px; */
}
@media (max-width: 1700px) {
  .album {
    height: 200px;
    width: 400px;
  }
  .vinyl {
    height: 200px;
    width: 200px;
    left: 100px;
  }

  .cover {
    height: 200px;
    width: 200px;
  }
}


