.App {
  text-align: center;
  width: 100%;
}

.float {
  position: fixed !important;
  color: #fff !important;
  bottom: 15px;
  right: 15px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  z-index: 999;
  border-radius: 3em;
  /* box-shadow: 2px 2px 3px #999; */
}

.bibo {
  background-color: #ed824c !important;
  color: #fff !important;
}

.marenostrum {
  background: #00676e !important;
  color: #fff !important;
}

.wally {
  background-color: #1ec163 !important;
  color: #fff !important;
}

.chakra-modal__content-container section {
  min-height: fit-content !important;
}

/* .marenostrum .css-1hcekp4:hover,
.css-1hcekp4[data-hover] {
  background: #ffa97e;
  color: #00676e;
  border-color: #fff;
}

.marenostrum .css-1hcekp4:active,
.css-1hcekp4[data-active] {
  background: #ffa97e;
  color: #00676e;
  border-color: #fff;
}
.marenostrum .css-1hcekp4:focus,
.css-1hcekp4[data-focus] {
  box-shadow: none;
} */

.iframe {
  height: -moz-available, -webkit-fill-available !important;
}

/* colores

#ed824c

#f0b37d

#e63f08 */
