h2 {
  font-size: 1.6em;
  margin: 0 30px 5px 30px;
  text-transform: uppercase;
}

h3 {
  font-size: 0.9em;
  margin: 0 30px 5px 30px;
  text-transform: uppercase;
}

p {
  margin: 0 30px 0 30px;
}

#grid {
  margin: auto;
  width: 90vw;
  display: grid;
  grid-row-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

.smallgrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.marenostrumbackground {
  padding-top: 55px;
  background-image: url("https://bibotarifa-chillout.web.app/assets/backMarenostrum.png");
  background-repeat: repeat;
  /* background-color: #f9ad84; */
}
section {
  min-height: 100vh;
}
.textHolder {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.odd > .desc {
  background-color: #db3a34;
  z-index: 4;
  width: 112%;
  margin-left: -10.5%;
  transform: perspective(1000px) rotateY(-30deg);
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  text-align: right;
}

.odd > .desc > .textHolder {
  right: 0;
}

.even > .desc {
  z-index: 2;
  background-color: #5bba6f;
  width: 112%;
  transform: perspective(1000px) rotateY(30deg);
  grid-column: 2 / 4;
  grid-row: 1 / 2;
}

.even > .photo {
  z-index: 3;
  width: 150%;
  margin-left: -23%;
  transform: perspective(1000px) rotateY(-45deg);
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.odd > .photo {
  z-index: 5;
  width: 150%;
  margin-left: -25%;
  transform: perspective(1000px) rotateY(45deg);
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}

img {
  display: block;
  width: 100%;
}
