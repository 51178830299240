.chilloutbackground {
  padding-top: 55px;
  background-image: url("https://bibotarifa-chillout.web.app/assets/BackBIBO.jpg");
  background-repeat: repeat;
  /* background-size: contain;  */
  /* background-color: #f9ad84; */
}
section {
  min-height: 100vh;
}

* {
  box-sizing: border-box;
  /*   border: 1px solid black; */
}
.shadow {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
body {
  font-family: "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

/* ul { */
/* display: flex;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style-type: none;
}

li {
  flex: 1;
  display: flex;
  align-items: stretch;
  padding: 20px;
  background-image: url("https://static.guiarepsol.com/fichas-gr/media/thumbnails/filer_public/70/24/7024fe3b-2410-4939-a1b8-c57cee8dbbb5/img_1468_1284x850_q75_middle.jpg");
  cursor: pointer;
  transition: all 0.5s ease;
}
li:hover {
  background: #e53f2e;
}
li.active {
  flex: 5;
  background: #fff;
  cursor: default;
}
li.active h2 {
  color: #e74c3c;
}
li.active .section-content {
  flex: 4;
  opacity: 1;
  transform: scaleX(1);
  color: #e74c3c;
}
li .section-title {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  color: white;
}
li .section-title h2 {
  margin: 0;
  transform: rotate(-90deg);
  white-space: nowrap;
}
li .section-content {
  flex: 1;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: all 0.25s 0.1s ease-out;
}

.polaroid {
  background: #fff;
  padding: 1rem;
  box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.2);
}
.polaroid > img {
  max-width: 100%;
  height: auto;
}
.caption {
  font-size: 1.8rem;
  text-align: center;
  line-height: 2em;
}
.item {
  width: 100%;
  display: inline-block;
  margin-top: 2rem;
  filter: grayscale(100%);
  justify-self: center;
}
.item .polaroid:before {
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.35s;
}
.item:nth-of-type(4n + 1) {
  transform: scale(0.8, 0.8) rotate(5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n + 1) .polaroid:before {
  transform: rotate(6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.4);
}
.item:nth-of-type(4n + 2) {
  transform: scale(0.8, 0.8) rotate(-5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n + 2) .polaroid:before {
  transform: rotate(-6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.4);
}
.item:nth-of-type(4n + 4) {
  transform: scale(0.8, 0.8) rotate(3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n + 4) .polaroid:before {
  transform: rotate(4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.3);
}
.item:nth-of-type(4n + 3) {
  transform: scale(0.8, 0.8) rotate(-3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n + 3) .polaroid:before {
  transform: rotate(-4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.3);
}
.item:hover {
  filter: none;
  transform: scale(1, 1) rotate(0deg) !important;
  transition: all 0.35s;
}
.item:hover .polaroid:before {
  content: "";
  position: absolute;
  z-index: -1;
  transform: rotate(0deg);
  height: 90%;
  width: 90%;
  bottom: 0%;
  right: 5%;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.2);
  transition: all 0.35s;
} */

.button-53 {
  background-color: #fff;
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  /* font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.75rem;
  padding: 0.75rem 1.65rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  width: 100%;
  /* max-width: 660px; */
  position: relative;
  cursor: pointer;
  transform: rotate(-2deg);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-53:focus {
  outline: 0;
}

.button-53:after {
  content: "";
  position: absolute;
  border: 1px solid #000000;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

.button-53:hover:after {
  bottom: 2px;
  left: 2px;
}

@media (min-width: 768px) {
  .button-53 {
    padding: 0.75rem 3rem;
    font-size: 1.25rem;
  }
}

/* .shadowImageBox {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
} */

.swiper-pagination span {
  background-color: #000000;
}

.product-colors span {
  width: 26px;
  height: 26px;
  top: 7px;
  margin-right: 12px;
  left: 10px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.pink {
  background-image: url("https://www.multiopticas.com/on/demandware.static/-/Sites-gafas/default/dw592e3b3a/images/swatch/43290104.jpg");
}
.black {
  background-image: url("https://www.multiopticas.com/on/demandware.static/-/Sites-gafas/default/dwc5d4f5fc/images/swatch/43290105.jpg");
}
.havana {
  background-image: url("https://www.multiopticas.com/on/demandware.static/-/Sites-gafas/default/dw82ce1ece/images/swatch/43290116.jpg");
}
.red-black {
  background-image: url("https://www.multiopticas.com/on/demandware.static/-/Sites-gafas/default/dw82ce1ece/images/swatch/43290116.jpg");
}
.carey {
  background-image: url("https://www.multiopticas.com/on/demandware.static/-/Sites-gafas/default/dw3e70b6e6/images/swatch/43290118.jpg");
}
.gold {
  background-image: url("https://www.multiopticas.com/on/demandware.static/-/Sites-gafas/default/dw23a269c8/images/swatch/43290123.jpg");
}
.blue {
  background-image: url("https://www.multiopticas.com/on/demandware.static/-/Sites-gafas/default/dw67c0911d/images/swatch/43290124.jpg");
}
.light-gold {
  background-image: url("https://www.multiopticas.com/on/demandware.static/-/Sites-gafas/default/dwbde2c2d2/images/swatch/32191581.jpg");
}
.gun-metal {
  background-image: url("https://www.multiopticas.com/on/demandware.static/-/Sites-gafas/default/dw9f8cf859/images/swatch/43290114.jpg");
}
.brown {
  background-image: url("https://www.multiopticas.com/on/demandware.static/-/Sites-gafas/default/dw7a5564e5/images/swatch/43290115.jpg");
}
.grey {
  background-image: url("https://www.multiopticas.com/on/demandware.static/-/Sites-gafas/default/dw6dee902d/images/swatch/45590108.jpg");
}

.product-colors :after {
  content: "";
  width: 36px;
  height: 36px;
  border: 2px solid #ccc;
  position: absolute;
  border-radius: 50%;
  box-sizing: border-box;
  left: -5px;
  top: -5px;
}

.polaroid {
  background: #fff;
  padding: 1rem;
  box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.2);
}
.polaroid > img {
  max-width: 100%;
  height: auto;
}

.caption {
  font-size: 1.8rem;
  text-align: center;
  line-height: 2em;
}

.boxPolaroid {
  padding-left: 100px;
  padding-right: 100px;
}

@media (max-width: 768px) {
  .boxPolaroid {
    padding-left: 0px;
    padding-right: 0px;
  }
}
