.ticket {
  width: 400px;
  height: 650px;
  background-color: white;
  margin: 25px auto;
  position: relative;
  color: black;
}

.holes-top {
  height: 50px;
  width: 50px;
  background-color: black;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-left: -25px;
  top: -25px;
}

.holes-top:before,
.holes-top:after {
  content: "";
  height: 50px;
  width: 50px;
  background-color: black;
  position: absolute;
  border-radius: 50%;
}

.holes-top:before {
  left: -200px;
}

.holes-top:after {
  left: 200px;
}

.holes-lower {
  position: relative;
  margin: 25px;
  border: 1px dashed #aaa;
}

.holes-lower:before,
.holes-lower:after {
  content: "";
  height: 50px;
  width: 50px;
  background-color: black;
  position: absolute;
  border-radius: 50%;
}

.holes-lower:before {
  top: -25px;
  left: -50px;
}

.holes-lower:after {
  top: -25px;
  left: 350px;
}

.title {
  padding: 27px 10px 3px;
  margin: 0px;
  text-align: start;
}

.cinema {
  color: #aaa;
  font-size: 22px;
}

.movie-title {
  font-size: 25px;
  font-weight: bold;
}

.info {
  padding: 5px 25px 0px 25px;
}

table {
  width: 100%;
  font-size: 15px;
  margin-bottom: 15px;
}

table tr {
  margin-bottom: 10px;
}

table th {
  text-align: left;
}

table th:nth-of-type(1) {
  width: 38%;
}

table th:nth-of-type(2) {
  width: 40%;
}

table th:nth-of-type(3) {
  width: 15%;
}

table td {
  width: 33%;
  font-size: 32px;
}

.bigger {
  font-size: 40px;
  text-align: start;
}

.serial {
  padding: 10px;
}

.serial table {
  border-collapse: collapse;
  margin: 0 auto;
}

.serial td {
  width: 3px;
  height: 50px;
}

.numbers td {
  font-size: 16px;
  text-align: center;
}

widget[type="ticket"] {
  width: 255px;
}

.ticket-flex {
  display: flex;
}

.ticket-flex .column {
  flex-direction: column;
}

.ticket-flex .row {
  flex-direction: row;
}

.ticket-flex .jsb {
  justify-content: space-between;
}

widget[type="ticket"] .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

widget[type="ticket"] .deetz {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

widget[type="ticket"] .top > div,
widget[type="ticket"] .bottom > div {
  padding: 0 18px;
}

widget[type="ticket"] .top > div:first-child,
widget[type="ticket"] .bottom > div:first-child {
  padding-top: 18px;
}

widget[type="ticket"] .top > div:last-child,
widget[type="ticket"] .bottom > div:last-child {
  padding-bottom: 18px;
}

widget[type="ticket"] .top,
widget[type="ticket"] .bottom,
widget[type="ticket"] .rip {
  background-color: #fff;
}

widget[type="ticket"] .top {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

widget[type="ticket"] .top .deetz {
  padding-bottom: 10px !important;
}

widget[type="ticket"] .bottom {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 18px;
  height: 55px;
  padding-top: 10px;
  width: 100%;
}

widget[type="ticket"] .bottom .barcode {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAF4AAAABCAYAAABXChlMAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuOWwzfk4AAACPSURBVChTXVAJDsMgDOsrVpELiqb+/4c0DgStQ7JMYogNh2gdvg5VfXFCRIZaC6BOtnoNFpvaumNmwb/71Frrm8XvgYkker1/g9WzMOsohaOGNziRs5inDsAn8yEPengTapJ5bmdZ2Yv7VvfPN6AH2NJx7nOWPTf1/78hoqgxhzw3ZqYG1Dr/9ur3y8vMxgNZhcAUnR4xKgAAAABJRU5ErkJggg==);
  background-repeat: repeat;
  min-width: 58px;
  width: 100%;
}

widget[type="ticket"] .bottom .buy {
  display: block;
  font-size: 12px;
  font-weight: bold;
  background-color: #5d9cec;
  padding: 0 18px;
  line-height: 30px;
  border-radius: 15px;
  color: #fff;
  text-decoration: none;
}

widget[type="ticket"] .rip {
  height: 20px;
  margin: 0 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAACCAYAAAB7Xa1eAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuOWwzfk4AAAAaSURBVBhXY5g7f97/2XPn/AcCBmSMQ+I/AwB2eyNBlrqzUQAAAABJRU5ErkJggg==);
  background-size: 4px 2px;
  background-repeat: repeat-x;
  background-position: center;
  position: relative;
  box-shadow: 0 1px 0 0 #fff, 0 -1px 0 0 #fff;
}

widget[type="ticket"] .rip:before,
widget[type="ticket"] .rip:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border: 5px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  box-sizing: unset;
}

widget[type="ticket"] .rip:before {
  left: -10px;
}

widget[type="ticket"] .rip:after {
  transform: translate(-50%, -50%) rotate(225deg);
  right: -40px;
}

widget .-bold {
  font-weight: bold;
}

